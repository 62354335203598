import "@hotwired/turbo-rails";
import "../controllers/index";
import "@rails/actiontext";
import "trix";

import hljs from "highlight.js";
import "highlight.js/styles/atom-one-dark.css";

document.addEventListener("turbo:load", () => {
  document.querySelectorAll("pre code").forEach((block) => {
    hljs.highlightBlock(block);
  });
});
